<template>
  <div class="bg_light">
    <div class="item border_bottom" @click="goToRoute">
      <div class="d_flex justify_content_between">
        <div class="status">
          <span v-if="status !== 1"></span>
        </div>
        <div class="d_flex flex_column align_items_start">
          <div class="d_flex justify_content_between">
            <div class="title" :class="{ font_sm: title && title.length > 6 }">
              {{ title }}
            </div>
            <div class="createdAt">
              {{ createdAt }}
            </div>
          </div>
          <div class="content">{{ content }}</div>
        </div>
        <div class="icon">
          <img src="../../../assets/images/public/ic_mine_return.webp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    content: {
      type: String,
      default: "内容",
    },
    createdAt: {
      type: String,
      default: "时间",
    },
    status: {
      type: Number,
      default: 0,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToRoute() {
      this.$emit("goToRoute", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: 8px;
  height: 8px;
  display: block;
  margin-right: 8px;
  align-self: flex-start;
  span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 8px;
    background-color: var(--orange);
  }
}
.bg_light {
  background: white;
  padding: 0rem 1.2rem 0rem 1.2rem;
}
.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.flex_column {
  flex-direction: column;
}
.justify_content_between {
  justify-content: space-between;
}
.align_items_start {
  align-items: flex-start;
  text-align: left;
}
.item {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0rem;
  box-sizing: border-box;
}
.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);
  margin-left: 0.5rem;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &_medium {
    width: 1.5rem;
    transform: translateY(0rem);
  }
}
.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}
.title {
  font-weight: bold;
  width: 10rem;
}
.font_sm {
  font-size: 0.85rem;
}
.content {
  font-size: 0.85rem;
  text-align: left;
  margin-top: 0.5rem;
  line-height: 1.5;
  color: var(--gray);
}
.createdAt {
  font-size: 0.85rem;
  text-align: right;
  color: var(--gray);
  margin-left: auto;
}
</style>
