<template>
  <Container theme="dark" :padding="false" ref="list" @scroll.native="loadMore">
    <template v-if="checkResponseStatus">
      <div>
        <MsgStatusItem
          v-for="(item, index) in data"
          :key="index"
          :title="item.title"
          :content="item.sendMessage"
          :createdAt="item.createdAt"
          :status="item.userMsgStatus"
          :id="item.id"
          @goToRoute="goToRoute"
          :class="{ mb_6rem: data.length > 5 && index === data.length - 1 }"
        ></MsgStatusItem>
      </div>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import Container from "../components/Container.vue";
import MsgStatusItem from "../components/MsgStatusItem.vue";
export default {
  components: {
    NoData,
    Container,
    MsgStatusItem,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      sendType: 0, //推送类型 0:系统消息 1：个人消息
      sendOsType: 0, //客户端类型 区分 0：安卓 1：苹果
      data: [],
      dataResponse: true,
    };
  },
  watch: {
    msgData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          if (newVal === []) return;
          this.data.push(...newVal);
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("msg", ["msgData"]),
    checkResponseStatus() {
      return this.data && this.data.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("msg", ["getMsg"]),
    ...mapActions("msg", ["getMsgStatus"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    getPlatform() {
      let platform = ["Win32", "Android", "iOS"];
      for (let i = 0; i < platform.length; i++) {
        if (navigator.platform.indexOf(platform[i]) > -1) {
          if (platform[i] === "Win32") {
            this.sendOsType = 0;
          } else {
            this.sendOsType = i - 1;
          }
        }
      }
    },
    goToRoute(id) {
      this.$router.push({
        path: "/my/msg/msgContent",
        query: { id: id },
      });
    },
    loadMore(e) {
      if (!this.dataResponse) return;
      let { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollHeight === scrollTop + clientHeight) {
        this.page++;
        this.getMsg({
          page: this.page,
          limit: this.limit,
          sendOsType: this.sendOsType,
          sendType: this.sendType,
        }).then((res) => {
          if (res.data && res.data.length === 0) {
            this.dataResponse = false;
          }
        });
      }
    },
  },
  created() {
    this.data = [];
    this.sendType = this.$route.meta.name === "系统消息" ? 0 : 1;
    this.getPlatform();
    this.getMsg({
      page: this.page,
      limit: this.limit,
      sendOsType: this.sendOsType,
      sendType: this.sendType,
    });
    this.getMsgStatus();
  },
};
</script>
